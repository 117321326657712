import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import CheckBoxBtn from "common/CheckBoxBtn";
import { duplicateCampaign } from "thunks";
import { onError, onSuccess } from "utils";
import _ from "i18n";
import TTPModal from "../../ui/TTPModal";
import { SVG_DUPLICATE_ICON } from "utils";
import { findKey } from "../../../services/utils";
import { CAMPAIGN_TYPES } from "../../../config/Common";

class DuplicateCampaignForm extends Component {
  constructor(props) {
    super(props);
    this.handleNameChange = e => this.setState({ name: e.target.value });

    this.state = {
      content: true,
      theme: true,
      recipient: true,
      tests: true,
      attachments: true,
      updating: false,
      signature: true,
    };

    this.modalStyle = {
      content: {
        border: "none",
        background: "rgb(255, 255, 255)",
        outline: "none",
        width: "60%",
        maxWidth: "40%",
        transition: "opacity 0.4s ease-out 0s",
        boxShadow:
          "0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2)",
        minWidth: "35rem",
        padding: "0 0"
      }
    };
  }

  toggleOption = optionName => {
    let newState = this.state;
    newState[optionName] = !newState[optionName];
    this.setState(newState);
  };

  processDuplication() {
    const { onCloseModal, dispatch, id, history, isUAVersion } = this.props;
    const { content, theme, recipient, tests, attachments, signature } = this.state;
    this.setState({ updating: true });

    dispatch(
        duplicateCampaign({ id, content, theme, recipient, tests, attachments, signature })
    ).then(
        res => {
            onCloseModal();
            if (res.value && res.value.data.data) {
                let { id, type, newsLetter } = res.value.data.data;
                let typeQuery = findKey(CAMPAIGN_TYPES, type);
                // Ajouter la gestion du type PLAIN_TEXT
                if (type === "DND_TEMPLATE" && newsLetter === 1) {
                    typeQuery = "newsletter";
                } else if (type === "PLAIN_TEXT") {
                    typeQuery = "text";
                }

                if (isUAVersion) {
                  if (type === "PLAIN_TEXT") {
                      this.props.handleSetCurrentPage("RICH_TEXT_PAGE", id);
                  } else {
                      this.props.handleSetCurrentPage("SEND_PAGE", id);
                  }
              } else {
                  history.push(`/campaign/${id}?type=${typeQuery}`);
              }
            }
            onSuccess(res, {
                body: "campaign_duplicated_successfully",
                title: "successfulAction"
            });
        },
        err => {
            this.setState({ updating: true });
            onError(err);
        }
    );
}


  render() {
    const { onCloseModal, campaignAttachments, isUAVersion, campaignType} = this.props;
    const {
      content,
      theme,
      recipient,
      tests,
      attachments,
      updating,
    } = this.state;
    return (
      <TTPModal
        isOpen={true}
        onClose={onCloseModal}
        modalStyle={this.modalStyle}
      >
        <div className="ttp-alert-dialog">
          <div className={`row alert-header align-center header-success`}>
            <div className="icon-container">{SVG_DUPLICATE_ICON}</div>
            <p className="alert-header__title">
            {isUAVersion 
              ? (campaignType === "PLAIN_TEXT" 
                  ? _("duplicateRitchTextTitle") 
                  : _("duplicateNewsletterTitle")
                )
              : _("Duplication options")}
          </p>
          </div>
          <div
            className="alert-message row"
            style={{ marginBottom: "-4rem" }}
          />
          {!isUAVersion ? (
          <div className="row modal-checkbox-group">
            <div className="column">
              <CheckBoxBtn
                checked={content}
                onChange={() => this.toggleOption("content")}
                label={_("content")}
              />
              {campaignAttachments.length !== 0 && (
                <CheckBoxBtn
                  checked={attachments}
                  onChange={() => this.toggleOption("attachments")}
                  label={_("attachments")}
                />
              )}
              <CheckBoxBtn
                checked={theme}
                onChange={() => this.toggleOption("theme")}
                label={_("template")}
              />
              <CheckBoxBtn
                checked={recipient}
                onChange={() => this.toggleOption("recipient")}
                label={_("recipients and filters")}
              />
              <CheckBoxBtn
                checked={tests}
                onChange={() => this.toggleOption("tests")}
                label={_("tests")}
              />
            </div>
          </div>
        ) : (
          <div className="row modal-description">
            <span>
              {campaignType === "PLAIN_TEXT" 
                ? _("duplicateRitchText") 
                : _("duplicateNewsletter")}
            </span>
          </div>
        )}
          <div className="column alert-footer">
            <button onClick={onCloseModal} className="cancel-button button">
              {_("cancel")}
            </button>
            <button
              onClick={!updating && this.processDuplication.bind(this)}
              className="ok-button  button bg-success"
            >
              {updating ? _("Processing...") : _("duplicate")}
            </button>
          </div>
        </div>
      </TTPModal>
    );
  }
}

export default compose(
  connect(),
  withRouter
)(DuplicateCampaignForm);

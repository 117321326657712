import React, { Component } from "react";
import { connect } from "react-redux";
import TopBar from "./TopBar";

import _ from "i18n";
import CampaignListFilter from "campaign/list/CampaignListFilter";
import CampaignList from "campaign/list/CampaignList";
import {
  initCampaignListFilter,
  initCampaignListItems,
  setEmailPaginationPage,
} from "../../actions/actions/campaign";
import TTPPaginator from "common/list/TTPPaginator";
import NotFound from "notFound/NotFound";
import NewsletterUASend from "./NewsletterUASend";
import PreviewSidebar from "./PreviewSidebar";
import {
  fetchCampaign,
  fetchCampaigns,
  fetchLastSentCampaign,
  persistCampaign,
} from "../../actions/thunks/campaign";
import {
  initCurrentEmailCampaignData,
  setCurrentCampaignTheme,
  setCurrentEmailCampaignData,
  setIsNlBlog,
} from "../../actions/actions/currentCampaign";
import {
  addFeedToNewsletter,
  initNewsletterBlocks,
  initNewsletterFeeds,
  setCurrentDndTheme,
} from "../../actions/actions/theme";
import { formatArticle } from "../../services/article";
import { NotificationManager } from "react-notifications";
import { hasValidLength, isValidEmail } from "../../services/validation";
import { onError, onSuccess } from "../../services/utils";
import {
  fetchNewsletterUAArticles,
  fetchNewsletterUAArticlesByPeriod,
} from "../../actions/thunks/newsletterUA";
import StatisticsPage from "./StatisticsPage";
import moment from "moment/moment";
import DrawerIframe from "../common/DrawerIframe/DrawerIframe";
import { TTP_BLOG_URL } from "../../config";
import ApprovalResponse from "../approval/feedback/ApprovalResponse";
import SubMenuUA from "./SubMenuUA";
import { ucFirst } from "../../services/common";
import ApprovalSettings from "../approval/setting/ApprovalSettings";
import ChoicePage from "./ChoicePage";
import RichText from "./RichText";
import SubscriptionPage from "./SubscriptionPage";
import MailExpressPage from "./MailExpressPage";

const mapStateToProps = (state) => ({
  campaigns: state.campaigns.list,
  filter: state.campaigns.list.filter,
  paginationPage: state.campaigns.list.emailPaginationPage,
  pageSize: state.campaigns.list.emailPageSize,
  currentTemplate: state.themes.newsletter.currentTemplate,
  blocks: state.themes.newsletter.blocks,
  currentTheme: state.themes.currentDndTheme,
  fetchingArticles: state.articles.fetching,
  currentCampaign: state.currentCampaign.email,
  updating: state.currentCampaign.email.updating,
  uaNewsletterId: state.currentCampaign.uaNewsletter.uaNewsletterId,
  isUaNewsletter: state.currentCampaign.uaNewsletter.isUaNewsletter,
  programmedDate: state.currentCampaign.uaNewsletter.programmedDate,
  id: state.currentCampaign.email.id,
  recipientType: state.currentCampaign.email.recipientType,
  manualRecipients: state.currentCampaign.email.manualRecipients,
  recipientsCount: state.campaigns.recipients.recipientsTotalCount,
  auth: state.auth,
  nbOfRecipients: state.currentCampaign.email.nbOfRecipients,
  language: state.currentCampaign.email.language,
  articleNewsletterId: state.currentCampaign.email.articleNewsletterId,
  currentStep: state.currentCampaign.email.currentSideBar,
  html: state.currentCampaign.email.html,
  idApproval: state.currentCampaign.uaNewsletter.idApproval,
  design: state.currentCampaign.email.design,
  isNlBlog: state.currentCampaign.uaNewsletter.isNlBlog,
  totalUniqueRecipients: state.campaigns.recipients.totalUniqueRecipients,
  templates: state.themes.list.items,
  totalUniqueRecipients: state.campaigns.recipients.totalUniqueRecipients,
  cmail: state.currentCampaign.uaNewsletter.cmail,
});

const mapDispatchToProps = (dispatch) => ({
  initCampaignListItems: () => dispatch(initCampaignListItems()),
  initCampaignListFilter: () => dispatch(initCampaignListFilter()),
  fetchCampaigns: () => dispatch(fetchCampaigns(true)),
  setEmailPaginationPage: (paginationPage) =>
    dispatch(setEmailPaginationPage(paginationPage)),
  setCurrentCampaignTheme: (theme) => dispatch(setCurrentCampaignTheme(theme)),
  setCurrentDndTheme: (theme) => dispatch(setCurrentDndTheme(theme)),
  fetchNewsletterUAArticles: (params) =>
    dispatch(fetchNewsletterUAArticles(params)),
  addFeedToNewsletter: (article) => dispatch(addFeedToNewsletter(article)),
  persistCampaign: (data) => dispatch(persistCampaign(data, true)),
  fetchCampaign: (id) => dispatch(fetchCampaign(id)),
  setCurrentEmailCampaignData: (scheduledDeliveryAt) =>
    dispatch(setCurrentEmailCampaignData(scheduledDeliveryAt)),
  fetchNewsletterUAArticlesByPeriod: (data) =>
    dispatch(fetchNewsletterUAArticlesByPeriod(data)),
  initNewsletterBlocks: () => dispatch(initNewsletterBlocks()),
  initNewsletterFeeds: () => dispatch(initNewsletterFeeds()),
  setCurrentCampaign: (data) => dispatch(setCurrentEmailCampaignData(data)),
  initCurrentCampaign: (data) => dispatch(initCurrentEmailCampaignData(data)),
  getLastSentCampaign: () => dispatch(fetchLastSentCampaign()),
  updateIsNlBlog: (isNlBlog) => dispatch(setIsNlBlog(isNlBlog)),
});
@connect(mapStateToProps, mapDispatchToProps)
export default class NewsletterUAPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "LIST_PAGE",
      // currentPage: "SUBSCRIPTION_PAGE",
      constructDesign: false,
      generateHtml: false,
      setNewsletter: false,
      showPreview: false,
      newsletterId: null,
      hasArticles: false,
      plan: "",
      isOpenModal: false,
      periodConfig: "",
      month: null,
      year: null,
      isPeriodChanged: false,
      isPeriodProgrammed: false,
      selectionChanged: false,
      allFiltersTab: [],
    };
  }

  componentDidMount() {
    const {
      initCampaignListItems,
      initCampaignListFilter,
      fetchCampaigns,
      isUaNewsletter,
      uaNewsletterId,
      auth,
      idApproval,
      isNlBlog,
      getLastSentCampaign,
      cmail,
    } = this.props;

    initCampaignListItems();
    initCampaignListFilter();
    getLastSentCampaign();

    if (idApproval !== null && idApproval !== "null") {
      this.setState({ currentPage: "APPROVAL_RESPONSE" });
    } else {
      fetchCampaigns();
    }

    if (cmail !== null && cmail !== "null") {
      this.setState({ currentPage: "SUBSCRIPTION_PAGE" });
    }

    if (isUaNewsletter === "true" || isNlBlog === "true") {
      this.setState({ currentPage: "SEND_PAGE" });
    }
    if (uaNewsletterId && uaNewsletterId !== "null") {
      this.setState({ newsletterId: uaNewsletterId, currentPage: "SEND_PAGE" });
    }
    const nlpreferences = auth.currentClient.newsletterPreferences;
    if (nlpreferences !== null) {
      if (nlpreferences?.apps?.length > 0) {
        var filteredApps = nlpreferences.apps.filter(
          (app) => app.type === "BLOG_NL_CREATE",
        );
        this.setState({
          plan: filteredApps[0].config,
        });
      }
    }
    window.addEventListener("message", this.handleMessageFromBlog);
  }

  componentDidUpdate(prevProps, prevState) {
    const { filter, paginationPage, pageSize, currentStep } = prevProps;
    const {
      blocks,
      setCurrentDndTheme,
      currentTheme,
      setCurrentCampaignTheme,
      initNewsletterBlocks,
      initNewsletterFeeds,
      templates,
    } = this.props;
    const {
      periodConfig,
      month,
      year,
      isPeriodChanged,
      hasArticles,
      selectionChanged,
      plan,
      currentPage,
    } = this.state;

    if (
      filter !== this.props.filter ||
      paginationPage !== this.props.paginationPage ||
      pageSize !== this.props.pageSize ||
      (prevState.currentPage !== currentPage && currentPage === "LIST_PAGE")
    ) {
      this.props.fetchCampaigns();
    }

    if (
      blocks !== prevProps.blocks &&
      prevProps.blocks.length === 0 &&
      !isPeriodChanged &&
      !hasArticles &&
      plan !== "PERSONALIZED"
    ) {
      this.fetchAllArticles();
    }

    if (
      (prevState.periodConfig !== periodConfig && periodConfig !== "") ||
      prevState.month !== month ||
      prevState.year !== year ||
      (prevState.selectionChanged !== selectionChanged && selectionChanged)
    ) {
      if (prevState.selectionChanged !== selectionChanged && selectionChanged) {
        NotificationManager.success(
          _("Data saved successfully"),
          _("successfulAction"),
        );
      }
      this.setState({ hasArticles: false, isPeriodChanged: true });
      //this.setState({ selectionChanged: false });
      initNewsletterFeeds();
      initNewsletterBlocks();

      this.setState({
        generateHtml: false,
        constructDesign: false,
        setNewsletter: false,
      });
      //this.setState({ showPreview: false });
      let data = { periodConfig, month, year };
      this.fetchPersonalizedArticles(data);
    }

    if (
      prevState.setNewsletter !== this.state.setNewsletter &&
      this.state.setNewsletter
    ) {
      let editor = this.editor || unlayer;
      editor.exportHtml(({ html, design }) => {
        if (templates && templates.length > 0) {
          let theme = {
            ...currentTheme,
            id: templates[0].id,
            design,
            content: html,
          };
          setCurrentDndTheme(theme);
          setCurrentCampaignTheme(theme);
          this.setState({ showPreview: true });
        } else {
          let theme = { ...currentTheme, design, content: html };
          setCurrentDndTheme(theme);
          setCurrentCampaignTheme(theme);
          this.setState({ showPreview: true });
        }
      });
    }

    if (currentStep === "PERIODS") {
      window.addEventListener("message", this.handleMessage);
    }
    if (currentPage !== prevState.currentPage && currentPage === "LIST_PAGE") {
      this.setState({
        constructDesign: false,
        generateHtml: false,
        setNewsletter: false,
        showPreview: false,
        newsletterId: null,
        hasArticles: false,
        periodConfig: "",
        month: null,
        year: null,
        isPeriodChanged: false,
        isPeriodProgrammed: false,
        selectionChanged: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  handleMessageFromBlog = (event) => {
    const { updateIsNlBlog } = this.props;
    if (event.data.from_blog && event.data.from_blog === "true") {
      this.setState({ currentPage: "SEND_PAGE" });
      updateIsNlBlog(true);
    }
  };

  handleMessage = (event) => {
    let url = TTP_BLOG_URL;
    if (url.endsWith("/")) {
      url = url.slice(0, -1);
    }
    if (event.origin !== url) {
      return;
    }
    this.setState({ isPeriodChanged: false });

    const {
      periodConfig,
      month,
      year,
      isPeriodProgrammed,
      selectionChanged,
      articleNewsletterId,
    } = event.data;
    const { setCurrentCampaign } = this.props;

    if (
      (this.state.periodConfig !== periodConfig && periodConfig !== "") ||
      this.state.month !== month ||
      this.state.year !== year ||
      this.state.isPeriodProgrammed !== isPeriodProgrammed ||
      (this.state.selectionChanged !== selectionChanged && selectionChanged)
    ) {
      this.setState({
        periodConfig: periodConfig,
        month: month,
        year: year,
        isPeriodProgrammed: isPeriodProgrammed,
        selectionChanged: selectionChanged,
      });
      setCurrentCampaign({ articleNewsletterId });
    }

    if (event.data.continue) {
      setCurrentCampaign({ currentSideBar: "PARAMS" });
    }
  };
  fetchAllArticles = () => {
    const { fetchNewsletterUAArticles, addFeedToNewsletter } = this.props;

    fetchNewsletterUAArticles()
      .then((res) => {
        const allArticles = res.value.data.data || [];
        let articlesForLite = [];
        let articlesForStandard = [];

        if (this.state.plan === "LITE") {
          if (allArticles.length > 0) {
            articlesForLite.push(allArticles[0]);
            if (allArticles.length > 5) {
              articlesForLite.push(allArticles[4]);
            } else if (allArticles.length > 1) {
              articlesForLite.push(allArticles[1]);
            }
          }
        } else if (this.state.plan === "STANDARD") {
          const priorityIndexes = [0, 1, 4, 5, 2, 3]; // Define the priority order of articles
          let articlesAdded = 0;

          for (
            let i = 0;
            i < priorityIndexes.length && articlesAdded < 4;
            i++
          ) {
            const index = priorityIndexes[i];

            if (index < allArticles.length) {
              articlesForStandard.push(allArticles[index]);
              articlesAdded++;
            }
          }
        }

        const articlesToAdd =
          articlesForLite.length > 0
            ? articlesForLite
            : articlesForStandard.length > 0
              ? articlesForStandard
              : allArticles;

        articlesToAdd.forEach((article) => {
          addFeedToNewsletter({
            ...formatArticle(article),
            type: "NEWS_LAYOUT",
          });
        });

        this.setState({
          constructDesign: articlesToAdd.length > 0,
          hasArticles: articlesToAdd.length > 0,
        });
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
        this.setState({ hasArticles: false, constructDesign: false });
      });
  };

  fetchPersonalizedArticles = (data) => {
    const {
      fetchNewsletterUAArticlesByPeriod,
      addFeedToNewsletter,
      auth,
      setCurrentCampaign,
    } = this.props;
    fetchNewsletterUAArticlesByPeriod(data)
      .then((res) => {
        setCurrentCampaign({ articleNewsletterId: res.value.data.data.id });
        const allArticles = res.value.data.data?.articles || [];
        const hasArticles = allArticles.length > 0;
        allArticles.forEach((article) => {
          addFeedToNewsletter({
            ...formatArticle(article),
            type: "NEWS_LAYOUT",
          });
        });
        this.setState({ constructDesign: hasArticles, hasArticles });
      })
      .catch((error) => {
        if (auth.currentClient.id !== 9 && !data.communityId) {
          data.communityId = 9;
          this.fetchPersonalizedArticles(data);
        } else {
          this.setState({ constructDesign: false, hasArticles: false });
        }
      });
  };
  handleGenerateHtml = (generateHtml) => {
    this.setState({ generateHtml });
  };

  handleSetNewsletter = (setNewsletter) => {
    this.setState({ setNewsletter });
  };

  handleCampaignListPageChange = ({ selected }) => {
    this.props.setEmailPaginationPage(selected + 1);
  };

  handleSetCurrentPage = (selected, id) => {
    this.setState({ newsletterId: id, currentPage: selected });
    this.handleGenerateHtml(false);
    this.props.initNewsletterFeeds();
  };

  renderPaginate() {
    let { campaigns, paginationPage, pageSize } = this.props;
    if (campaigns.error != null && campaigns.error.code === 404) {
      return "";
    }
    return (
      <TTPPaginator
        onPageChange={this.handleCampaignListPageChange}
        pageSize={pageSize}
        nbResult={campaigns.nbResult}
        paginationPage={paginationPage}
      />
    );
  }

  toggleCreateNewsletterPage = () => {
    this.setState({ newsletterId: null, currentPage: "CHOICE_PAGE" });
  };

  handleShowPreview = () => {
    const {
      setCurrentDndTheme,
      currentTheme,
      setCurrentCampaignTheme,
      templates,
    } = this.props;
    const { setNewsletter } = this.state;

    const sidebar = document.getElementById("settings__sidebar");
    if (sidebar) {
      sidebar.classList.add("display");
      const mask = document.getElementsByClassName("mask").item(0);
      mask.classList.remove("hide");
    }
    if (setNewsletter) {
      let editor = this.editor || unlayer;
      editor.exportHtml(({ html, design }) => {
        if (templates && templates.length > 0) {
          let theme = {
            ...currentTheme,
            id: templates[0].id,
            design,
            content: html,
          };
          setCurrentDndTheme(theme);
          setCurrentCampaignTheme(theme);
          this.setState({ showPreview: true, selectionChanged: false });
        } else {
          let theme = { ...currentTheme, design, content: html };
          setCurrentDndTheme(theme);
          setCurrentCampaignTheme(theme);
          this.setState({ showPreview: true, selectionChanged: false });
        }
      });
    }
  };

  mapCampaignGroupIds(campaignGroups) {
    let oldCampaignGroups = this.props.currentCampaign.oldCampaignGroups;
    if (!oldCampaignGroups || !oldCampaignGroups.length) {
      return campaignGroups;
    }
    oldCampaignGroups.forEach((oldCampaignGroup) => {
      for (let i = 0; i < campaignGroups.length; i++) {
        if (
          campaignGroups[i].group === oldCampaignGroup.group.id &&
          campaignGroups[i].status === oldCampaignGroup.status
        ) {
          campaignGroups[i].id = oldCampaignGroup.id;
        }
      }
    });
    return campaignGroups;
  }

  getCampaignGroups() {
    let campaignGroups = [];
    let { allowedGroups, deniedGroups } = this.props.currentCampaign;
    if (!(allowedGroups instanceof Array)) {
      allowedGroups = allowedGroups.split(",");
    }
    if (!(deniedGroups instanceof Array)) {
      deniedGroups = deniedGroups.split(",");
    }
    if (allowedGroups.length > 0) {
      for (let i = 0; i < allowedGroups.length; i++) {
        if (allowedGroups[i]) {
          campaignGroups.push({ group: allowedGroups[i], status: "INCLUDE" });
        }
      }
    }
    if (deniedGroups.length > 0) {
      for (let i = 0; i < deniedGroups.length; i++) {
        if (deniedGroups[i]) {
          campaignGroups.push({ group: deniedGroups[i], status: "EXCLUDE" });
        }
      }
    }
    return this.mapCampaignGroupIds(campaignGroups);
  }

  canSaveData = () => {
    let { from, fromName, object, language, pageUrl, type, html } =
      this.props.currentCampaign;
    let hasValidContent =
      type === "WEB_PAGE"
        ? pageUrl && pageUrl.length > 0
        : html && html.length > 0;

    return (
      isValidEmail(from) &&
      hasValidLength(fromName, 3) &&
      hasValidLength(object, 5, 200) &&
      language &&
      hasValidContent
    );
  };

  handleSaveCampaign = (action = "DRAFT") => {
    let { allFiltersTab } = this.state;
    let { totalUniqueRecipients, manualRecipients, nbOfRecipients } =
      this.props;
    if (!this.canSaveData()) {
      NotificationManager.info(
        _("Merci de vérifier l'aperçu avant d'envoyer la newsletter"),
      );
      return;
    }

    // Détermine le type en fonction de la currentPage
    let type;
    if (this.state.currentPage === "SEND_PAGE") {
      type = "DND_TEMPLATE";
    } else if (this.state.currentPage === "RICH_TEXT_PAGE") {
      type = "PLAIN_TEXT";
    } else {
      // Ajouter une gestion des erreurs ou un type par défaut si nécessaire
      type = "UNKNOWN_TYPE";
    }

    const selectAllRecipientsTypes =
      allFiltersTab.length > 0 &&
      allFiltersTab.every((recipient) => recipient.selectAllRecipient === true);

    let data = {
      campaignGroups: this.getCampaignGroups(),
      action,
      type,
      newsLetter: 1,
      isUAVersion: true,
    };

    if (selectAllRecipientsTypes) {
      data.nbUniqueRecipients = totalUniqueRecipients
        ? totalUniqueRecipients +
          (manualRecipients ? manualRecipients.length : 0)
        : 0;
    }

    let notification = { body: "campaignAdded", title: "addCampaignSuccess" };
    this.handleGenerateHtml(false);

    if (this.props.isUaNewsletter) {
      this.setState({ newsletterId: null });
      if (
        this.props.programmedDate != null &&
        moment(this.props.programmedDate).isValid()
      ) {
        this.props.setCurrentEmailCampaignData({
          id: null,
          scheduledDeliveryAt: this.props.programmedDate,
        });
      }
    }

    this.props.persistCampaign(data).then(
      (res) => {
        onSuccess(res, notification);
        this.props.fetchCampaigns();
        this.setState({
          newsletterId: null,
          generateHtml: false,
          currentPage: "LIST_PAGE",
        });
      },
      (err) => onError(err),
    );
  };

  handleShowIframe = () => {
    let { language, auth } = this.props;
    let isNlSending = this.state.currentPage === "PROGRAM_PAGE" ? false : true;

    const params = [];
    params.push("without_header=1");
    params.push("fiduciary=" + auth.currentClient.id);
    params.push("isNlSending=" + isNlSending);
    params.push("periodConfig=" + this.state.periodConfig);

    return (
      <DrawerIframe
        src={`${TTP_BLOG_URL}${language}/programming-newsletters`}
        params={params}
        isBlog={true}
      />
    );
  };
  changeHasArticles = (value) => {
    this.setState({ hasArticles: value });
  };
  changeAllFiltersTab = (value) => {
    this.setState({ allFiltersTab: value });
  };

  render() {
    let { campaigns, updating, isUaNewsletter, nbOfRecipients, cmail } =
      this.props;

    let {
      currentPage,
      constructDesign,
      showPreview,
      generateHtml,
      newsletterId,
      hasArticles,
      isOpenModal,
      plan,
      isPeriodChanged,
      isPeriodProgrammed,
      periodConfig,
    } = this.state;

    var isRecipientsNull = (nbOfRecipients || 0) < 1;
    return (
      <div className="newsletter-ua">
        <div className={`newsletter-ua-page`}>
          {currentPage !== "SEND_PAGE" &&
            currentPage !== "RICH_TEXT_PAGE" &&
            !(cmail !== null && cmail !== "null") && (
              <SubMenuUA
                currentPage={currentPage}
                handleSetCurrentPage={this.handleSetCurrentPage}
                plan={plan}
              />
            )}

          {currentPage === "PROGRAM_PAGE" && this.handleShowIframe()}
          {currentPage === "APPROVAL_PAGE" && (
            <ApprovalSettings isUAVersion={true} />
          )}

          {(currentPage === "SEND_PAGE" ||
            currentPage === "RICH_TEXT_PAGE") && (
            <TopBar
              label="send an email"
              onClose={currentPage !== "LIST_PAGE" && this.handleSetCurrentPage}
              plan={plan}
            >
              {(currentPage === "SEND_PAGE" ||
                currentPage === "RICH_TEXT_PAGE") && (
                <div className="btn-container">
                  <button
                    className="btn success text-uppercase"
                    disabled={currentPage === "SEND_PAGE" && !hasArticles}
                    onClick={this.handleShowPreview}
                  >
                    {_("aperçu")}
                  </button>
                  <button
                    className="btn save text-uppercase"
                    onClick={() =>
                      !updating && this.handleSaveCampaign("DRAFT")
                    }
                    disabled={isRecipientsNull}
                  >
                    {updating && (
                      <img
                        src={"/img/icons/tail-spin.svg"}
                        className={"loading"}
                      />
                    )}
                    {updating
                      ? _("Processing")
                      : isUaNewsletter === "true"
                        ? _("aproveAndSave")
                        : _("save")}
                  </button>
                </div>
              )}
            </TopBar>
          )}

          {currentPage === "LIST_PAGE" && (
            <div className="newsletter-ua-page__list">
              <div className="newsletter-ua-page__sub-header">
                <div className="newsletter-ua-page__sub-header__info">
                  <div className="newsletter-ua-page__sub-header__info__title">
                    <img src={"/img/icons/inbox.svg"} />
                    <div className="title">
                      <h3>{_("liste campagnes")}</h3>
                      <p className="text-uppercase">
                        {_("visualiser et gérer vos campagnes de newsletters")}
                      </p>
                    </div>
                  </div>
                  <button
                    className="text-uppercase newsletter-ua-page__sub-header__btn"
                    onClick={this.toggleCreateNewsletterPage}
                    //disabled={!hasArticles}
                    title={!hasArticles ? _("warningEmptyNewsletter") : ""}
                  >
                    <img src={"/img/icons/addNl.svg"} />
                    {_("créer une campagne")}
                  </button>
                </div>
              </div>
              <CampaignListFilter />
              <hr className="h-separator" />
              {campaigns.fetching ? (
                <div className="nb-campaigns gradient"></div>
              ) : (
                <div className="row medium-11 nb-campaigns">
                  {ucFirst(_("number_of_campaigns"))} {" :"}&nbsp;
                  <span>{campaigns.nbResult}</span>
                </div>
              )}
              {campaigns.error != null && campaigns.error.code === 404 ? (
                <NotFound
                  principalText="Aucune campagne d'email n'a été crée"
                  text="Commencez par créer une nouvelle Newsletter "
                />
              ) : (
                <div
                  className={`row align-center medium-11 m-t-s`}
                  style={{ position: "relative", zIndex: "0" }}
                >
                  <CampaignList
                    campaigns={campaigns}
                    isUAVersion={true}
                    handleSetCurrentPage={this.handleSetCurrentPage}
                  />
                </div>
              )}
              {!campaigns.fetching && (
                <div className={`row align-center margin-bottom-2`}>
                  <div className={"column medium-11"}>
                    {this.renderPaginate()}
                  </div>
                </div>
              )}
            </div>
          )}
          {currentPage === "SEND_PAGE" && (
            <NewsletterUASend
              constructDesign={constructDesign}
              handleGenerateHtml={this.handleGenerateHtml}
              generateHtml={generateHtml}
              handleSetNewsletter={this.handleSetNewsletter}
              newsletterId={newsletterId}
              plan={plan}
              handleShowIframe={this.handleShowIframe}
              isPeriodProgrammed={isPeriodProgrammed}
              periodConfig={periodConfig}
              fetchAllArticles={this.fetchAllArticles}
              changeHasArticles={this.changeHasArticles}
              changeAllFiltersTab={this.changeAllFiltersTab}
              handleSetCurrentPage={this.handleSetCurrentPage}
            />
          )}
          {currentPage === "STATS_PAGE" && (
            <StatisticsPage
              newsletterId={newsletterId}
              handleSetCurrentPage={this.handleSetCurrentPage}
            />
          )}
          {currentPage === "APPROVAL_RESPONSE" && (
            <ApprovalResponse
              handleSetCurrentPage={this.handleSetCurrentPage}
            />
          )}
          {currentPage === "CHOICE_PAGE" && (
            <ChoicePage
              currentPage={currentPage}
              handleSetCurrentPage={this.handleSetCurrentPage}
            />
          )}
          {currentPage === "RICH_TEXT_PAGE" && (
            <RichText
              constructDesign={constructDesign}
              handleGenerateHtml={this.handleGenerateHtml}
              generateHtml={generateHtml}
              handleSetNewsletter={this.handleSetNewsletter}
              newsletterId={newsletterId}
              plan={plan}
              handleShowIframe={this.handleShowIframe}
              isPeriodProgrammed={isPeriodProgrammed}
              periodConfig={periodConfig}
              handleSetCurrentPage={this.handleSetCurrentPage}
              currentPage={currentPage}
            />
          )}
          {currentPage === "SUBSCRIPTION_PAGE" && (
            <SubscriptionPage
              handleSetCurrentPage={this.handleSetCurrentPage}
            />
          )}
          <MailExpressPage />
        </div>
        <div className="mask hide" />

        <PreviewSidebar
          showPreview={showPreview}
          newsletterId={newsletterId}
          isPeriodChanged={isPeriodChanged}
          currentPage={currentPage}
        />
      </div>
    );
  }
}

import axios from "../axios-api";
import { updateSourceToken, throwCatchedError } from "utils";

var getUsersSettingsSourceToken;

export const getUsersSettings = (
  token,
  word,
  role,
  organizationId,
  sourceToken = null,
) => {
  getUsersSettingsSourceToken = updateSourceToken(
    getUsersSettingsSourceToken,
    sourceToken,
  );

  const fields = [
    "id",
    "firstName",
    "lastName",
    "avatar",
    "role",
    "emailingAuthorisation",
  ];

  let roleGroup = "1";
  if (role) {
    roleGroup = role.slice(0, 1);
    role = role.slice(1);
  }

  let requestUrl = `${roleGroup == 2 ? "/organization/user" : "/authorization/apps-authorization"}`;
  let filter = [];

  if (roleGroup == "2") {
    filter.push({
      property: "role.type",
      value: role,
      operator: "eq",
    });
    filter.push({
      property: "role.organization",
      value: organizationId,
      operator: "eq",
    });
  } else {
    if (role && role !== "ALL") {
      requestUrl += "?appRole=" + role;
    }
  }
  if (word !== "" && word.length >= 3) {
    filter.push({
      property: "name",
      value: escape(word.trim()),
      operator: "like",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    limit: 100,
    organization_id: organizationId,
    app: "EMAILING",
  };

  return axios
    .get(requestUrl, {
      cancelToken: getUsersSettingsSourceToken.token,
      params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getUser = (token, id) => {
  const fields = ["id", "firstName", "lastName", "avatar"];

  let requestUrl = `/organization/user/${id}`;

  let params = {
    access_token: token,
    fields: fields.join(","),
  };

  return axios
    .get(requestUrl, {
      params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getUserAvatar = (token, userId) => {
  let requestUrl = `/organization/user/${userId}/avatar`;

  let params = {
    access_token: token
  };

  return axios.get(requestUrl, { params })
    .then(response => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to fetch user avatar');
      }
    })
    .catch(error => {
      console.error('Error fetching user avatar:', error);
      throw error;
    });
};

export const saveUsersSettings = (token, role, allowCreateCampaign, allowSendCampaign, allowCreateSms, allowSendSms, usersIds, clientId) => {
  const requestUrl = `/authorization/multi-save?access_token=${token}`;
  var formData = new FormData();
  for (let userId of usersIds) {
    formData.append("users_id[]", userId);
  }
  formData.append("client_id", clientId);
  formData.append("role", role);
  formData.append("preferences[allowCreateCampaign]", allowCreateCampaign);
  formData.append("preferences[allowSendCampaign]", allowSendCampaign);
  formData.append("preferences[allowCreateSms]", allowCreateSms);
  formData.append("preferences[allowSendSms]", allowSendSms);
  formData.append("application", "EMAILING");
  return axios.post(requestUrl, formData);
};

export const deleteUsersSettings = (token, usersIds, clientId) => {
  const requestUrl = `/authorization/multi-delete?access_token=${token}`;

  var formData = new FormData();
  for (let userId of usersIds) {
    formData.append("users_id[]", userId);
  }
  formData.append("client_id", clientId);
  formData.append("application", "EMAILING");

  return axios.post(requestUrl, formData);
};

export const changeContactDefaultCommunityEmail = (
  token,
  contactClientId,
  contactEmail,
  clientId,
  contactId,
) => {
  const requestUrl = `/mailing/contact/${contactId}`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", contactEmail);
  formData.append("contactClientId", contactClientId);
  formData.append("client", clientId);
  formData.append("isContactClient", true);

  return axios.post(requestUrl, formData);
};

export const changeUserMainEmail = (
  token,
  userId,
  email,
  clientId,
  mainEmail,
) => {
  const requestUrl = `/organization/user/change-main-email`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);
  formData.append("mainEmail", mainEmail);
  formData.append("client", clientId);
  formData.append("userId", userId);

  return axios.post(requestUrl, formData);
};

import * as actions from "actions";
import {
  getCampaign,
  getCampaigns,
  getEmails,
  getRecipients,
  registerEmail,
  removeCampaign,
  saveCampaign,
  updateStatus,
  duplicateCampaignData,
  completeCampaign,
  getCampaignBroadcasters,
  getCampaignEmails,
  getCampaignNewTargetsList,
  changeCampaignScheduledAtDate,
  saveRecipientsCriteria,
  getRecipientsCriteria,
  getLastSentCampaign,
  getCountsRecipients,
  getTotalRecipients,
  getUaRecipients,
  getFormulasStats,
  getMembershipClientsList,
  getDelegationTypes,
} from "TamtamAPI";
import { pushSourceToken } from "utils";
import { getHistory } from "../../router/getHistory";

export const persistCampaign =
  (data, isUAVersion = false) =>
  (dispatch, getState) => {
    const {
      auth,
      currentCampaign,
      campaigns,
      themes: { newsletter },
    } = getState();
    const { detached, currentClient, user } = auth;
    let clientId = (currentClient && currentClient.id) || null;
    let userId = detached && user ? user.id : null;

    const { token } = auth;
    data = { ...currentCampaign["email"], ...data };
    if (token == "" || (!clientId && !userId)) {
      return;
    }
    let totalRecipients = 0;
    if (isUAVersion) {
      totalRecipients = data.nbOfRecipients;
    } else {
      if (data.recipientType === "MANUAL") {
        totalRecipients = data.manualRecipients
          ? data.manualRecipients.length
          : 0;
      } else if (data.recipientType == "REBROADCAST") {
        totalRecipients = data.broadcastRecipients
          ? data.broadcastRecipients.length
          : 0;
      } else if (
        campaigns.recipients &&
        campaigns.recipients.recipientsTotalCount
      ) {
        totalRecipients = campaigns.recipients.recipientsTotalCount;
      }
    }

    data.totalRecipients = totalRecipients;
    if (data.newsLetter) {
      data.fields = JSON.stringify(newsletter.blocks);
    }

    if (data.recipientType === "OECCBB_MEMBERS") {
      data.manualRecipients = campaigns.recipients.items.map(
        (recipient) => recipient.user.mainEmail,
      );
    }

    return dispatch(
      actions.saveCurrentEmailCampaign(
        saveCampaign(token, data, clientId, userId).then((res) => {
          const history = getHistory();

          const query = new URLSearchParams(history.location.search);
          const returnTo = query.get("returnto");
          if (!isUAVersion) {
            if (data.action === "DRAFT") {
              history.push("/campaigns");
            } else if (data.action === "SEND" && data.isRebroadcasted) {
              history.push(`/campaigns`);
            } else if (data.action === "SEND") {
              history.push(
                `/stats/${res.data.data.id}${
                  returnTo ? "?returnto=" + returnTo : ""
                }`,
              );
            } else if (!data.id) {
              let type = res.data.data.type === "WEB_PAGE" ? "web" : "text";
              history.push(
                `/campaign/${res.data.data.id}?type=${type}${
                  returnTo ? "&returnto=" + returnTo : ""
                }`,
              );
            }
          }
          return res.data.data;
        }),
      ),
    );
  };

export const deleteCampaign = (campaignId) => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(
    actions.removeCampaign(
      removeCampaign(token, campaignId).then(
        (res) => {
          return res.data.data;
        },
        (err) => err,
      ),
    ),
  );
};

export const fetchCampaigns =
  (isNewsletter = false) =>
  (dispatch, getState) => {
    const { token, user, detached, currentClient } = getState().auth;
    const { emailPaginationPage, emailPageSize } = getState().campaigns.list;
    const { status, language, subject, type, recipientType, dateRange } =
      getState().campaigns.list.filter;
    const { sortConfig } = getState().campaigns.sort;
    const { isUaVersion } = getState().campaigns.recipients;

    let data = {
      emailPaginationPage,
      emailPageSize,
      status,
      language,
      subject,
      type,
      recipientType,
      sortConfig,
      dateRange,
      isUaVersion,
    };

    if (currentClient && currentClient.id) {
      data.clientId = currentClient.id;
    } else if (detached) {
      data.userId = user.id;
    }

    return dispatch(actions.fetchCampaigns(getCampaigns(token, data)));
  };

export const fetchCampaign = (campaignId) => (dispatch, getState) => {
  let sourceToken = pushSourceToken("FETCH_CAMPAIGN", dispatch);
  const { token } = getState().auth;

  return dispatch(
    actions.fetchCurrentEmailCampaign(
      getCampaign(token, campaignId, sourceToken),
    ),
  );
};

export const fetchMembrshipClientsList =
  (data, witoutPagination) => (dispatch, getState) => {
    const { token } = getState().auth;
    const { pageSize, paginationPage } = getState().campaigns.recipients.filter;

    return dispatch(
      actions.fetchMembrshipClientsList(
        witoutPagination
          ? getMembershipClientsList(token, data)
          : getMembershipClientsList(token, data, paginationPage, pageSize),
      ),
    );
  };

export const fetchMembrshipClientsStats = (data) => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(
    actions.fetchMembrshipClientsStats(getFormulasStats(token, data)),
  );
};

export const fetchCampaignBroadcasters =
  (broadcastedCampaign) => (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.fetchCampaignBroadcasters(
        getCampaignBroadcasters(token, broadcastedCampaign),
      ),
    );
  };

export const fetchRecipients = (filterData) => (dispatch, getState) => {
  let sourceToken = pushSourceToken("FETCH_RECIPIENTS", dispatch);

  const { token } = getState().auth;
  const { eventFilters, recipientType, mailingLists, commitmentScoreRange } =
    getState().currentCampaign.email;
  const { paginationPage, pageSize, name } =
    getState().campaigns.recipients.filter;
  let clientId = getState().auth.currentClient.id;

  filterData = {
    ...{ recipientType, ...filterData },
    eventFilters,
    clientId,
    name,
    mailingLists,
    commitmentScoreRange,
  };
  return dispatch(
    actions.fetchRecipients(
      getRecipients(token, paginationPage, pageSize, filterData, sourceToken),
    ),
  );
};

export const registerFromEmail = (email) => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(actions.registerEmail(registerEmail(token, email)));
};

export const fetchEmails =
  (email, authorizedOnly = true, noLimit = false) =>
  (dispatch, getState) => {
    let sourceToken = pushSourceToken("FETCH_EMAILS", dispatch);
    const { token } = getState().auth;
    let client = null;

    if (authorizedOnly) {
      client = getState().auth.currentClient.id;
    }

    let clients = getState().auth.user.clients;

    let authUserAdminRoleClients = [];

    clients.forEach((client) => {
      if (client.authorisation.role == "ADMIN") {
        authUserAdminRoleClients.push(client.id);
      }
    });

    return dispatch(
      actions.fetchEmails(
        getEmails(
          token,
          { email, client, authorizedOnly, authUserAdminRoleClients, noLimit },
          sourceToken,
        ),
      ),
    );
  };

export const updateCampaignStatus = (data) => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(actions.setCampaignStatus(updateStatus(token, data)));
};

export const duplicateCampaign = (data) => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(
    actions.duplicateCampaign(duplicateCampaignData(token, data)),
  );
};

export const completeCampaignSending = (data) => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(actions.completeCampaign(completeCampaign(token, data)));
};

export const fetchCampaignEmails = (campaignId) => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(
    actions.fetchCampaignEmails(getCampaignEmails(token, campaignId)),
  );
};

export const fetchCampaignNewTargetsList =
  (campaignId) => (dispatch, getState) => {
    const { token } = getState().auth;
    const { paginationPage, pageSize } = getState().campaigns.recipients.filter;
    return dispatch(
      actions.fetchRecipients(
        getCampaignNewTargetsList(token, campaignId, paginationPage, pageSize),
      ),
    );
  };

export const changeCampaignScheduledAt = (data) => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(
    actions.changeScheduledAtDate(changeCampaignScheduledAtDate(token, data)),
  );
};

export const fetchUaRecipients = (filterData) => (dispatch, getState) => {
  let sourceToken = pushSourceToken("FETCH_RECIPIENTS", dispatch);

  const { token } = getState().auth;
  const { recipientType } = getState().currentCampaign.email;
  const { paginationPage, pageSize, name } =
    getState().campaigns.recipients.filter;
  let clientId = getState().auth.currentClient.id;

  filterData = {
    ...{ recipientType, ...filterData },
    clientId,
    name,
  };
  return dispatch(
    actions.fetchRecipients(
      getUaRecipients(token, paginationPage, pageSize, filterData, sourceToken),
    ),
  );
};

export const persistRecipientsCriteria = (data) => (dispatch, getState) => {
  const { auth } = getState();
  const { currentClient, user } = auth;
  let clientId = (currentClient && currentClient.id) || null;
  let userId = user ? user.id : null;
  const { token } = auth;
  data = { ...data };
  if (token === "" || (!clientId && !userId)) {
    return;
  }
  return dispatch(
    actions.saveRecipientsCriteria(
      saveRecipientsCriteria(token, data, clientId, userId).then((res) => {
        return res.data.data;
      }),
    ),
  );
};

export const fetchRecipientsCriteria = () => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(
    actions.fetchRecipientsCriteria(
      getRecipientsCriteria(token).then((res) => {
        return res.data;
      }),
    ),
  );
};
export const fetchLastSentCampaign = () => (dispatch, getState) => {
  const { auth } = getState();
  const { token, currentClient } = auth;
  let clientId = (currentClient && currentClient.id) || null;
  return dispatch(
    actions.fetchLastSentCampaign(
      getLastSentCampaign(token, clientId).then((res) => {
        return res.data;
      }),
    ),
  );
};

export const fetchCountsRecipientsByTypes =
  (recipientTypes, groupsFilter) => (dispatch, getState) => {
    const { auth } = getState();
    const { token, currentClient } = auth;
    let clientId = (currentClient && currentClient.id) || null;
    return dispatch(
      actions.fetchCountsRecipientsByTypes(
        getCountsRecipients(token, clientId, recipientTypes, groupsFilter).then(
          (res) => {
            return res.data;
          },
        ),
      ),
    );
  };

export const fetchTotalRecipients = (filterData) => (dispatch, getState) => {
  let sourceToken = pushSourceToken("FETCH_RECIPIENTS", dispatch);

  const { token } = getState().auth;
  const { recipientType } = getState().currentCampaign.email;
  const { paginationPage, pageSize, name } =
    getState().campaigns.recipients.filter;
  let clientId = getState().auth.currentClient.id;

  filterData = {
    ...{ recipientType, ...filterData },
    clientId,
    name,
  };
  return dispatch(
    actions.fetchTotalRecipients(
      getTotalRecipients(
        token,
        paginationPage,
        pageSize,
        filterData,
        sourceToken,
      ),
    ),
  );
};
export const fetchDelegationTypes = () => (dispatch, getState) => {
  const { token } = getState().auth;
  let clientId = getState().auth.currentClient.id;
  let sourceToken = pushSourceToken("FETCH_DELEGATION_TYPE", dispatch);
  return dispatch(
    actions.fetchDelegationTypes(
      getDelegationTypes(token, clientId, sourceToken),
    ),
  );
};

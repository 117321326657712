import * as actions from "actions";
import {
  getUsersSettings,
  saveUsersSettings,
  deleteUsersSettings,
  getUser,
  changeContactDefaultCommunityEmail,
  changeUserMainEmail,
  getUserAvatar,
} from "TamtamAPI";
import { pushSourceToken } from "utils";

export const fetchUsers = () => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    const { searchWord, role } = getState().settings.filter;
    let sourceToken = pushSourceToken("FETCH_USERS", dispatch);
    let clientId = currentClient.id;

    return dispatch(
      actions.getUsersSettings(
        getUsersSettings(token, searchWord, role, clientId, sourceToken),
      ),
    );
  };
};
export const fetchUser = (id) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    return dispatch(actions.getUser(getUser(token, id)));
  };
};

export const fetchUserAvatar = (id) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    return dispatch(actions.getUser(
      getUserAvatar(token, id)
    ));
  };
};

export const fixContactEmail = (contactClientId, contactEmail, contactId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    return dispatch(
      actions.fixContactDefaultEmail(
        changeContactDefaultCommunityEmail(
          token,
          contactClientId,
          contactEmail,
          clientId,
          contactId,
        ),
      ),
    );
  };
};

export const updateUsersSettings = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let {
      role,
      allowSendCampaign,
      allowCreateCampaign,
      allowCreateSms,
      allowSendSms,
      selectedUsers,
    } = getState().settings;
    let clientId = getState().auth.currentClient.id;
    if (role !== "NONE") {
      return dispatch(
        actions.saveUsers(
          saveUsersSettings(
            token,
            role,
            allowCreateCampaign ? 1 : 0,
            allowSendCampaign ? 1 : 0,
            allowCreateSms ? 1 : 0,
            allowSendSms ? 1 : 0,
            selectedUsers,
            clientId,
          ).then((res) =>
            dispatch(
              actions.updateUsers({
                role,
                allowCreateCampaign: allowCreateCampaign ? 1 : 0,
                allowSendCampaign: allowSendCampaign ? 1 : 0,
                allowCreateSms: allowCreateSms ? 1 : 0,
                allowSendSms: allowSendSms ? 1 : 0,
                selectedUsers,
              }),
            ),
          ),
        ),
      );
    } else {
      return dispatch(
        actions.saveUsers(
          deleteUsersSettings(token, selectedUsers, clientId).then((res) =>
            dispatch(
              actions.updateUsers({
                selectedUsers,
              }),
            ),
          ),
        ),
      );
    }
  };
};

export const fixUserMainEmail = (userId, email, mainEmail) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    return dispatch(
      actions.fixUserMainEmail(
        changeUserMainEmail(token, userId, email, clientId, mainEmail),
      ),
    );
  };
};

import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import TTPSelect from "common/TTPSelect";
import { setEventsFilters } from "actions";
import { USER_ROLE_TYPES } from "Common";
import {fetchClientFolders} from "../../../../actions/thunks/filters";
import {fetchMembrshipClientsList} from "../../../../actions/thunks/campaign";
import {fetchMembrshipClientsStats} from "../../../../actions/thunks/campaign";
import {
  setCurrentEmailCampaignData
} from "../../../../actions/actions/currentCampaign";

@connect((store) => {
  return {
    recipients: store.campaigns.recipients,
    formulas: store.campaigns.list.formulas,
  }
}, (dispatch) => {
  return {
    setCurrentEmailCampaignData: (data) => dispatch((setCurrentEmailCampaignData(data))),
    fetchMembrshipClientsList: (data) => dispatch(fetchMembrshipClientsList(data)),
    fetchMembrshipClientsStats: (data) => dispatch(fetchMembrshipClientsStats(data)),
  }
})
export default class OeccbbMembersFilters extends Component {

  constructor(props) {
    super(props);
    this.yearsOptions = [{value: 2024, label: 2024},{value: 2023, label: 2023},{value: 2022, label: 2022},{value: 2021, label: 2021}, ]
    this.statusOptions = [{value: 'PAID', label: _('paid')},{value: 'PENDING', label: _('pending')}]
    this.sourceOptions = [{value: 'UA', label: 'UA'},{value: 'OECCBB', label: 'OECCBB'}]
    this.state = {
      year: 2024,
      source: '',
      formulas: [],
      statut: 'PAID'
    }
  }

  handleYearChange = (selected) => {
    this.setState({...this.state, year: selected});
    this.props.setCurrentEmailCampaignData({...this.state, year: selected})
    this.props.fetchMembrshipClientsList({...this.state, status: this.state.statut, year: selected});
  }

  handleStatusChange = (selected) => {
    this.setState({...this.state, status: selected});
    this.props.setCurrentEmailCampaignData({...this.state, statut: selected})
    this.props.fetchMembrshipClientsList({...this.state, status: selected});
  }

  handleSourceChange = (selected) => {
    this.setState({...this.state, source: selected});
    this.props.setCurrentEmailCampaignData({...this.state, source: selected})
    this.props.fetchMembrshipClientsList({...this.state, status: this.state.statut, source: selected});
  }

  handleFormulasChange = (selected) => {
    this.setState({...this.state, formulas: selected});
    this.props.setCurrentEmailCampaignData({...this.state, formulas: selected})
    this.props.fetchMembrshipClientsList({...this.state, status: this.state.statut, formulas: selected});
  }

  componentDidUpdate(prevProps, prevState) {
    let { filter } = this.props.recipients;
    let state = this.state;
    if (filter !== prevProps.recipients.filter ) {
      this.props.fetchMembrshipClientsList(state);
    }
  }


  componentDidMount() {
    this.props.fetchMembrshipClientsList({
      year: 2024,
      source: '',
      formulas: [],
      status: 'PAID'
    });
    this.props.fetchMembrshipClientsStats({
      year: 2024,
      source: '',
      formulas: [],
      status: 'PAID'
    });
  }

  render() {
    const {year, source, formulas, statut} = this.state;
    const formulasOptions = this.props.formulas?.map((item, index) => ({label: item.formula, value: index + 1})) ?? [];

    return (
      <>
      <div className="row">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('year')}</h1>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={year}
              placeholder={_('Year')}
              options={this.yearsOptions}
              onChange={this.handleYearChange}/>
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Status')}</h1>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={statut}
              placeholder={_('Status')}
              options={this.statusOptions}
              onChange={this.handleStatusChange}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('source')}</h1>
            <TTPSelect
              simple={true}
              values={source}
              placeholder={_('Source')}
              options={this.sourceOptions}
              onChange={this.handleSourceChange}/>
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Formulas')}</h1>
            <TTPSelect
              notClearable={true}
              values={formulas}
              placeholder={_('select_formule')}
              options={formulasOptions}
              onChange={this.handleFormulasChange}/>
          </div>
        </div>
      </div>
      </>
    );
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";

import { fetchEmails } from "thunks";

import _ from "i18n";
import { getUserNameForAvatar } from "utils";
import debounce from "lodash.debounce";
import { LoadingSuggestion } from "./LoadingSuggestion";

@connect((store) => {
  return {
    usersSuggestions: store.emails.items,
    loadingUsersSuggestions: store.emails.fetching,
    suggestionsError: store.emails.error,
  };
})
export default class UsersSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = { userInputValue: "", fetchValue: null };
    this.debouncedLoadUsersSuggestions = debounce(
      this.loadUsersSuggestions,
      1000,
    );
  }

  loadUsersSuggestions(value) {
    if (value != this.state.fetchValue) {
      const { dispatch } = this.props;
      dispatch(fetchEmails(value, true, true));
      this.setState({ fetchValue: value });
    }
  }

  handleSelectUser = (user) => {
    this.props.onSelectUser(user);
    this.setState({ userInputValue: "" });
  };

  render() {
    const { usersSuggestions, loadingUsersSuggestions, suggestionsError } =
      this.props;
    const { userInputValue } = this.state;
    const inputProps = {
      placeholder: _("Add a member") + " ...",
      value: userInputValue,
      onChange: (event, { newValue }) =>
        this.setState({ userInputValue: newValue }),
      autoFocus: "true",
    };
    return (
      <Autosuggest
        suggestions={usersSuggestions}
        shouldRenderSuggestions={(value) => value && value.trim().length > 2}
        onSuggestionsFetchRequested={({ value }) =>
          this.debouncedLoadUsersSuggestions(value)
        }
        onSuggestionsClearRequested={() =>
          this.setState({ usersSuggestions: [] })
        }
        onSuggestionSelected={(e, { suggestion }) => {
          this.handleSelectUser(suggestion);
        }}
        getSuggestionValue={({ user }) => user.firstName + " " + user.lastName}
        renderSuggestion={(suggestion) => {
          const { user, email } = suggestion;
          return (
            <div className="user-suggestion__item">
              {user.avatarUrl ? (
                <div
                  className="img-wrap"
                  style={{ backgroundImage: `url(${user.avatarUrl})` }}
                ></div>
              ) : (
                <div className="img-wrap empty-avatar">
                  <span style={{ fontSize: "inherit" }}>
                    {getUserNameForAvatar(user.firstName, user.lastName)}
                  </span>
                </div>
              )}
              <div className="content">
                <h4>
                  {user.firstName} {user.lastName}
                </h4>
                <p>{email}</p>
              </div>
            </div>
          );
        }}
        inputProps={inputProps}
        renderSuggestionsContainer={({ containerProps, children, query }) => (
          <LoadingSuggestion
            containerProps={containerProps}
            loadingsSuggestions={loadingUsersSuggestions}
            suggestionsError={suggestionsError}
            value={query}
          >
            {children}
          </LoadingSuggestion>
        )}
      />
    );
  }
}
